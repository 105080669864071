import React, { createContext } from 'react';
import App from 'next/app';
import { Router } from 'server/pages';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons';

import ScrollToTheTopButton from 'components/ScrollToTheTopButton';
import { scrollToTop } from 'lib/page';
import ReactPiwik from 'react-piwik';

import bugsnagClient from 'lib/bugsnag';

import { wrapper } from 'webSpecificRedux/configureStore';

import 'styles/main.scss';
import 'styles/separate_main.scss';
import { segmentIdentify, segmentPage } from 'lib/segment';
import { defaultTitle } from 'components/page';
import { isUserAuthenticatedSelector } from 'store/selectors/userAuth';
import { connect } from 'react-redux';
import { getPersonalDataSelector } from 'store/selectors/user';
import { md5 } from 'lib/encrypt';
import { getCookie, setCookie } from 'lib/cookie';
import { trackingUtmRequest } from 'store/actions/common';

const ErrorBoundary = bugsnagClient.getPlugin('react');

library.add(faStroopwafel);
const siteId = process.env.MATOMO_SITE_ID ? process.env.MATOMO_SITE_ID : 3;
// Router.events.on('routeChangeComplete', url => segmentPage(Router.router.asPath || url));
Router.onRouteChangeComplete = url => {
  scrollToTop();

  if (url === Router.router.asPath) {
    ReactPiwik.push(['setCustomUrl', Router.router.asPath]);
    ReactPiwik.push(['trackPageView']);
  }
};

// eslint-disable-next-line no-unused-vars
const piwik = new ReactPiwik({
  url: 'https://matomo.car.co.uk',
  siteId,
  trackErrors: false,
  jsFilename: `${process.env.MATOMO_FILENAME}.js`,
  phpFilename: `${process.env.MATOMO_FILENAME}.php`,
});

export const PageURLContext = createContext(process.env.BASE_APP_URL);

class CarCoUkFrontendApp extends App {
  static async getInitialProps({ Component, ctx }) {
    let pageProps;
    let pageUrl;

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps({ ...ctx, isServer: !!ctx?.req });
    }

    if (ctx && ctx.req) {
      pageUrl = `${ctx?.req?.protocol}://${ctx.req.headers.host}${ctx?.req?.originalUrl}`;
    }

    return pageProps ? { pageProps, pageUrl } : { pageUrl };
  }

  mutationObserver;

  segmentPageTrack(currentUrl) {
    const urls = currentUrl.split('/').filter(str => str);
    let pageTypes;
    if (urls.length >= 1) {
      pageTypes = urls.map(url =>
        url
          .split('-')
          .map(str => str.replace(str[0], str[0].toUpperCase()))
          .join(' '),
      );
    } else {
      pageTypes = ['Home'];
    }
    const currentTitle = document.title;
    const pageType = pageTypes[0];

    if ((currentTitle === defaultTitle || !currentTitle) && pageType !== 'Home') {
      this.mutationObserver.observe(document.querySelector('title'), {
        subtree: true,
        characterData: true,
        childList: true,
      });
    } else {
      this.mutationObserver.disconnect();
      if (this.props.isUserAuthenticated && this.props.personalData) {
        const { firstName, lastName, email, phoneNumber } = this.props.personalData;
        if (email) {
          segmentIdentify(md5(email), {
            email,
            ...(phoneNumber ? { phone: phoneNumber } : {}),
            ...(firstName ? { first_name: firstName } : {}),
            ...(lastName ? { last_name: lastName } : {}),
          });
        }
      }
      segmentPage({ title: currentTitle, name: pageTypes.join(' - ') });
    }
  }

  componentDidMount() {
    this.mutationObserver = new MutationObserver(() => {
      if (document.title !== defaultTitle && document.title !== '') {
        this.segmentPageTrack(document?.location?.pathname || '');
      }
    });
    this.segmentPageTrack(document?.location?.pathname || '');
    Router.events.on('routeChangeComplete', url => {
      this.mutationObserver.disconnect();
      this.segmentPageTrack(url || '');
    });
    const query = Router?.router?.query;
    if (query?.utm_source) {
      // TODO: add a request
      const utmInfo = Object.entries({
        utm_source: query?.utm_source,
        utm_medium: query?.utm_medium,
        utm_campaign: query?.utm_campaign,
        utm_content: query?.utm_content,
        utm_term: query?.utm_term,
        gclid: query?.gclid,
      }).reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null) {
          acc[key] = value;
        }
        return acc;
      }, {});

      this.props?.trackingUTM(utmInfo);

      const existUtmTracking = getCookie('utm_tracking');
      if (!existUtmTracking) {
        setCookie(
          'utm_tracking',
          JSON.stringify(
            Object.assign(utmInfo, {
              landing_time: new Date(),
            }),
          ),
          null,
          null,
          5,
        );
      }
    }
  }

  render() {
    const { Component, pageProps, pageUrl } = this.props;

    return (
      <ErrorBoundary>
        <div className="app-wrapper">
          <PageURLContext.Provider value={pageUrl}>
            <Component {...pageProps} />
          </PageURLContext.Provider>
          <ScrollToTheTopButton />
        </div>
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state) {
  return {
    personalData: getPersonalDataSelector(state),
    isUserAuthenticated: isUserAuthenticatedSelector(state),
  };
}
const mapDispatchToProps = dispatch => ({
  trackingUTM: data => dispatch(trackingUtmRequest(data)),
});

export default wrapper.withRedux(connect(mapStateToProps, mapDispatchToProps)(CarCoUkFrontendApp));
