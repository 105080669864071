import { createActions } from 'redux-actions';

import CommonTypes from '../action-types/common';

export const { deleteFilesRequest, deleteFilesSuccess, deleteFilesError } = createActions({
  [CommonTypes.DELETE_FILES.REQUEST]: payload => payload,
  [CommonTypes.DELETE_FILES.SUCCESS]: payload => payload,
  [CommonTypes.DELETE_FILES.ERROR]: payload => payload,
});

export const { trackingUtmRequest, trackingUtmSuccess, trackingUtmError } = createActions({
  [CommonTypes.TRACKING_UTM.REQUEST]: payload => payload,
  [CommonTypes.TRACKING_UTM.SUCCESS]: payload => payload,
  [CommonTypes.TRACKING_UTM.ERROR]: payload => payload,
});
