import React, { useContext, useEffect } from 'react';
import Head from 'next/head';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { isUserAuthenticatedSelector } from 'store/selectors/userAuth';
import { getUserDataRequest } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getUserStatusSelector } from 'store/selectors/user';
import { getCookie } from 'lib/cookie';
import { authenticate, refreshTokenRequest } from 'store/actions/user';
import { PageURLContext } from '../pages/_app';

const defaultDescription =
  'Find the very best deals for car finance, insurance, warranties, car valuation, MOT history and scrap cars - along with news, reviews, and helpful guides!';
export const defaultTitle = 'The Home of Everything Automotive - Car.co.uk';

const Page = ({ title, description, keywords, image, type, children, noIndex, customContents }) => {
  const pageUrl = useContext(PageURLContext);
  const url = pageUrl || (typeof window !== 'undefined' && window.location?.href);
  const httpsUrl = url.replace(/^http:\/\//, 'https://');
  const excludeCanonicalUrls = [
    '/car-finance/car-finance-calculator',
    '/car-finance/confirmation',
    '/car-finance/your-employment-details',
    '/car-finance/contact-details',
    '/car-finance/your-address',
  ];
  const isHideCanonical = excludeCanonicalUrls.some(path => httpsUrl.includes(path));
  const origin = typeof window !== 'undefined' && window.location?.origin;
  const routerUrl = url.replace(origin, '');
  const defaultType = routerUrl.includes('/media') || routerUrl.includes('/faqs') ? 'article' : 'website';

  const isAuthenticated = useSelector(isUserAuthenticatedSelector);
  const userStatus = useSelector(getUserStatusSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthenticated) {
      if (userStatus !== 'ready') {
        dispatch(getUserDataRequest());
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // judge auth again
    const accessToken = getCookie('access_token');
    const refreshToken = getCookie('refresh_token');
    if (accessToken) {
      dispatch(authenticate({ token: accessToken }));
    } else if (refreshToken) {
      dispatch(refreshTokenRequest({ token: refreshToken }));
    }
  }, []);

  return (
    <div>
      <Head>
        <title>{title || defaultTitle}</title>
        <meta
          name="ahrefs-site-verification"
          content="7251fbfeeb100f18321323e7dcc7598aa4aa0db0676ea5103d5549325aeac671"
        />
        <meta charSet="utf-8" />
        <meta name="description" content={description || defaultDescription} />
        <meta property="og:type" content={type || defaultType} />
        <meta property="og:url" content={httpsUrl} />
        <meta property="og:title" content={title || defaultTitle} />
        <meta property="og:description" content={description || defaultDescription} />
        {!isHideCanonical && <link rel="canonical" href={httpsUrl} />}
        <meta
          httpEquiv="Content-Security-Policy"
          content="child-src 'self' https://*.google.com https://*.stripe.com https://*.facebook.com https://widget.trustpilot.com/ https://carcouk.autoserver.co.uk;"
        ></meta>
        {noIndex && <meta name="robots" content="noindex,nofollow" />}
        {keywords && <meta name="keywords" content={keywords || ''} />}
        {image && <meta property="og:image" content={image} />}
        {customContents?.length > 0 &&
          customContents.map(item => {
            return <meta {...item} />;
          })}

        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Head>
      {children}
    </div>
  );
};

Page.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  keywords: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
  noIndex: PropTypes.bool,
  customContents: PropTypes.any,
};

Page.defaultProps = {
  title: null,
  description: null,
  keywords: null,
  image: null,
  type: null,
  noIndex: false,
  customContents: [],
};

export default withRouter(Page);
